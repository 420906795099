<template>
  <v-col
    class="d-flex flex-column justify-center align-center bg_colorSet"
    :class="$mq"
  >
    <v-img v-if="$mq !== 'sm'" src="@/assets/nap_01.jpg"> </v-img>
    <v-img v-else-if="$mq === 'sm'" src="@/assets/nap_m_01.jpg"> </v-img>
    <!-- <v-img src="@/assets/nap.jpg"> </v-img> -->
    <div v-if="$mq !== 'sm'" class="nap_thumbnail">
      <div>
        <div class="circle">
          <div
            class="circle_info"
            @click="healing_view(0)"
            v-html="$ml.get('asmr_thumbnail_title_01')"
          ></div>

          <div
            class="circle_sub_info"
            @click="healing_view(0)"
            v-html="$ml.get('asmr_thumbnail_title_sub')"
          ></div>

          <v-img
            @click="healing_view(0)"
            class="circle_img"
            src="@/assets/nap_thumb_1.jpg"
          />
        </div>
      </div>
      <div>
        <div class="circle">
          <div
            class="circle_info ml-n3"
            @click="healing_view(1)"
            v-html="$ml.get('asmr_thumbnail_title_02')"
          ></div>

          <div
            class="circle_sub_info"
            @click="healing_view(1)"
            v-html="$ml.get('asmr_thumbnail_title_sub')"
          ></div>

          <v-img
            @click="healing_view(1)"
            class="circle_img"
            src="@/assets/nap_thumb_2.jpg"
          />
        </div>
      </div>
      <div>
        <div class="circle">
          <div
            class="circle_info ml-6"
            @click="healing_view(2)"
            v-html="$ml.get('asmr_thumbnail_title_03')"
          ></div>

          <div
            class="circle_sub_info"
            @click="healing_view(2)"
            v-html="$ml.get('asmr_thumbnail_title_sub')"
          ></div>

          <v-img
            @click="healing_view(2)"
            class="circle_img"
            src="@/assets/nap_thumb_3.jpg"
          />
        </div>
      </div>
      <div>
        <div class="circle">
          <div
            class="circle_info reposition"
            @click="healing_view(3)"
            v-html="$ml.get('asmr_thumbnail_title_04')"
          ></div>

          <div
            class="circle_sub_info"
            @click="healing_view(3)"
            v-html="$ml.get('asmr_thumbnail_title_sub')"
          ></div>

          <v-img
            @click="healing_view(3)"
            class="circle_img"
            src="@/assets/nap_thumb_4.jpg"
          />
        </div>
      </div>
    </div>

    <div v-if="$mq === 'sm'" class="m_nap_thumbnail">
      <div class="divide_contents">
        <div class="circle_position">
          <div>
            <div class="m_circle">
              <div
                class="m_circle_info ml-2"
                @click="healng_view(0)"
                v-html="$ml.get('asmr_thumbnail_title_01')"
              ></div>

              <div
                class="m_circle_sub_info"
                @click="healing_view(0)"
                v-html="$ml.get('asmr_thumbnail_title_sub')"
              ></div>

              <v-img
                @click="healing_view(0)"
                class="m_circle_img"
                src="@/assets/nap_thumb_1.jpg"
              />
            </div>
          </div>
          <div>
            <div class="m_circle">
              <div
                class="m_circle_info"
                @click="healing_view(1)"
                v-html="$ml.get('asmr_thumbnail_title_02')"
              ></div>

              <div
                class="m_circle_sub_info"
                @click="healing_view(1)"
                v-html="$ml.get('asmr_thumbnail_title_sub')"
              ></div>

              <v-img
                @click="healing_view(1)"
                class="m_circle_img"
                src="@/assets/nap_thumb_2.jpg"
              />
            </div>
          </div>
        </div>
        <div class="circle_position">
          <div>
            <div class="m_circle">
              <div
                class="m_circle_info m_reposition_padding ml-1"
                @click="healing_view(2)"
                v-html="$ml.get('asmr_thumbnail_title_03')"
              ></div>

              <div
                class="m_circle_sub_info"
                @click="healing_view(2)"
                v-html="$ml.get('asmr_thumbnail_title_sub')"
              ></div>

              <v-img
                @click="healing_view(2)"
                class="m_circle_img"
                src="@/assets/nap_thumb_3.jpg"
              />
            </div>
          </div>
          <div>
            <div class="m_circle">
              <div
                class="m_circle_info m_reposition ml-2"
                @click="healing_view(3)"
                v-html="$ml.get('asmr_thumbnail_title_04')"
              ></div>

              <div
                class="m_circle_sub_info"
                @click="healing_view(3)"
                v-html="$ml.get('asmr_thumbnail_title_sub')"
              ></div>

              <v-img
                @click="healing_view(3)"
                class="m_circle_img"
                src="@/assets/nap_thumb_4.jpg"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <v-img v-if="$mq !== 'sm'" src="@/assets/nap_02.jpg"> </v-img>
    <v-img v-else-if="$mq === 'sm'" src="@/assets/nap_m_02.jpg"> </v-img>

    <!-- <v-row
      align="center"
      justify="center"
      style="width:100%; height: auto; margin:auto; padding-bottom:30px;"
    > -->
    <v-dialog
      :content-class="'popup thumb_popup'"
      v-model="healing_popup"
      width="860"
      persistent
      v-if="healing_popup && is_small"
    >
      <v-btn
        elevation="0"
        icon
        class="small_or_big_btn"
        width="80"
        height="80"
        v-on:click="small = false"
      >
        <img width="80" aspect-ratio="1" src="@/assets/btFull.png" />
      </v-btn>
      <!-- youtube -->
      <v-card
        v-if="healing_popup_gubun == 'youtube'"
        class="popup_root"
        style="padding:34px;"
      >
        <v-card-actions>
          <v-spacer />
          <iframe
            type="text/html"
            v-bind:src="healing_popup_url"
            scrolling="no"
            frameborder="0"
            style="border-radius:10px; z-index: 300; width:100%; height:calc(90vh - 330px);"
          ></iframe>
          <v-spacer />
        </v-card-actions>

        <v-row justify="center" class="title_menu">
          <v-hover
            v-slot="{ hover }"
            class="common_btn"
            style="cursor: pointer; margin: 0px 12px 0px 12px;"
          >
            <v-card
              :elevation="hover ? 12 : 2"
              class="button_1"
              :class="{ 'on-hover': hover }"
              v-on:click="$router.go(-1)"
            >
              <v-row
                class="fullheight justify-center align-center button_text"
                v-html="$ml.get('contents_close_btn')"
              >
              </v-row>
            </v-card>
          </v-hover>
        </v-row>
      </v-card>
      <!-- else -->
      <iframe
        v-else
        type="text/html"
        v-bind:src="healing_popup_url"
        scrolling="no"
        frameborder="0"
        width="100%"
        height="100%"
        style="z-index: 300;"
      ></iframe>
    </v-dialog>

    <v-dialog
      :content-class="'dialog_full'"
      v-model="healing_popup"
      persistent
      v-else-if="healing_popup"
    >
      <v-btn
        v-if="$mq != 'sm'"
        elevation="0"
        icon
        class="small_or_big_btn"
        width="80"
        height="80"
        v-on:click="small = true"
      >
        <img width="80" aspect-ratio="1" src="@/assets/btNormal.png" />
      </v-btn>
      <!-- youtube -->
      <v-card
        v-if="healing_popup_gubun == 'youtube'"
        class="popup_root"
        style="padding-top:34px;padding-left:10px;padding-right:10px;padding-bottom:34px;background:#000000;"
      >
        <v-card-actions>
          <v-spacer />
          <iframe
            type="text/html"
            allowfullscreen="allowfullscreen"
            v-bind:src="healing_popup_url"
            scrolling="no"
            frameborder="0"
            style="border-radius:10px; z-index: 300; width:100vw; height:66vw;background:#000000;"
          ></iframe>
          <v-spacer />
        </v-card-actions>

        <v-row justify="center" class="title_menu">
          <v-hover
            v-slot="{ hover }"
            class="common_btn"
            style="cursor: pointer; margin: 0px 12px 0px 12px;"
          >
            <v-card
              :elevation="hover ? 12 : 2"
              class="button_1"
              :class="{ 'on-hover': hover }"
              v-on:click="$router.go(-1)"
            >
              <v-row
                class="fullheight justify-center align-center button_text"
                v-html="$ml.get('contents_close_btn')"
              >
              </v-row>
            </v-card>
          </v-hover>
        </v-row>
      </v-card>
      <!-- else -->
      <iframe
        v-else
        type="text/html"
        width="100%"
        height="100%"
        v-bind:src="healing_popup_url"
        scrolling="no"
        frameborder="0"
        style="z-index: 300;margin-bottom: -10px;"
      ></iframe>
    </v-dialog>
    <!-- </v-row> -->
  </v-col>
</template>

<script>
export default {
  props: ["title"],
  created() {
    var target = this.$route.fullPath.substring(
      this.$route.fullPath.indexOf("#")
    );
    if (target == "#healing_popup") {
      this.$router.go(-1);
    }
  },
  data() {
    return {
      url_link: [
        "https://www.youtube.com/embed/dTu4fKByJPk",
        "https://www.youtube.com/embed/f7yLKcAsk1Y",
        "https://www.youtube.com/embed/lQ0fS2meTYQ",
        "https://www.youtube.com/embed/Yuw8TnTei58"
      ],
      healing_popup: false,
      healing_popup_url: "",
      healing_popup_gubun: "",
      small: true
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
    is_small() {
      if (this.small) {
        if (this.$mq != "sm") {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }
  },
  methods: {
    open_url_link(num) {
      window.open(this.url_link[num], "_blank");
    },

    healing_view: function(index) {
      this.healing_popup_url = this.url_link[index];
      this.healing_popup_gubun = "youtube";

      this.$router.push({ hash: "#healing_popup" });
    }
  },

  watch: {
    $route(to) {
      //팝업 띄움
      var target = to.fullPath.substring(to.fullPath.indexOf("#"));

      if (target == "#healing_popup") {
        this.healing_popup = true;
      } else if (target.indexOf("#") < 0) {
        this.healing_popup = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "src/assets/css/common";
.bg_colorSet {
  padding: 0;
  // background-color: $bg-color-contents-tanksdiary;
}

.nap_thumbnail {
  background-color: $bg_nap_thumbnail;
  width: 100%;
  height: 300px;
  display: flex;
}

.nap_thumbnail > div {
  flex: 1;
  // padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  // gap: 10px;
  // flex-flow: wrap;
  // border-radius: 50%;
}

.circle {
  width: 190px;
  height: 190px;
  // border: 1px solid white;
  border-radius: 50%;
}

// .circle > span {
//   user-select: none;
//   cursor: pointer;
//   position: absolute;
//   text-align: center;
//   // padding-left: -65px;
//   margin-left: -65px;
//   margin-top: 70px;
//   // left: 8%
//   // top: 0%;
//   // top: 87%;
//   z-index: 5;
//   color: white;
//   font-size: 25px;
// }

.circle_info {
  // user-select: none;
  cursor: pointer;
  position: absolute;
  text-align: center;
  padding-left: 40px;
  margin-top: 70px;
  z-index: 5;
  color: white;
  font-size: 25px;
}

.circle_sub_info {
  // user-select: none;
  cursor: pointer;
  position: absolute;
  text-align: center;
  padding-left: 60px;
  margin-top: 105px;
  z-index: 5;
  color: white;
  font-size: 25px;
}

.reposition {
  // padding-left: 30px;
}

.urlYoutube {
  width: 850px;
  height: 500px;
  margin-bottom: 100px;
}

.circle_img {
  cursor: pointer;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-size: contain;
  background-color: black;
  opacity: 0.5;
}

/* mobile */
.sm.bg_colorSet {
  .urlYoutube {
    width: 80vw;
    height: 50vw;
    margin-bottom: 5vw;
  }

  .thx_send_button {
    height: 10vw !important;
    font: normal normal bold 4vw Noto !important;
  }

  .m_nap_thumbnail {
    background-color: $bg_nap_thumbnail;
    width: 100%;
    height: 90vw;
    display: flex;
    // flex-flow: nowrap;
  }

  .circle_position {
    // flex: 1;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    // background-color: tan;
    gap: 0%;
  }

  .m_circle {
    width: 40vw;
    height: 40vw;
    border-radius: 50%;
    margin: 10px;
  }

  .divide_contents {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .m_circle_img {
    cursor: pointer;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-size: contain;
    background-color: black;
    opacity: 0.5;
  }

  .m_circle_info {
    cursor: pointer;
    position: absolute;
    text-align: center;
    padding-left: 7vw;
    padding-top: 17vw;
    z-index: 5;
    color: white;
    font-size: 5vw;
  }

  .m_circle_sub_info {
    cursor: pointer;
    position: absolute;
    text-align: center;
    padding-left: 13vw;
    padding-top: 24vw;
    z-index: 5;
    color: white;
    font-size: 5vw;
  }

  .m_reposition {
    padding-left: 8vw;
  }

  .m_reposition_padding {
    padding-left: 13vw;
  }
}
</style>

<style lang="scss" scoped>
@import "src/assets/css/common";
.icon {
  width: 180px;
  height: 180px !important;
  padding: 0px !important;
  margin: 12px 0px;
  border-radius: 8px;
  cursor: pointer;
  background-size: cover;
  background-position: center;

  font-family: Noto;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  color: $ft-color-contents-icon-text;
}

.icon_skin {
  width: 180px;
  height: 180px;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.4);
  display: table;
}

.icon_text {
  width: 160px;
  white-space: break-spaces;
  height: 44px;
  overflow: hidden;
  display: table-cell;
  vertical-align: middle;
}

.swiper-item {
  background-color: white;
  display: inline-block;
  margin: 5px 5px 0px 5px;
  width: 44px;
  height: 7px;
  // border: 1px solid $bg-color-contents-swiper-item-border;
  // border-radius: 5px;
  opacity: 0.6;
}

.swiper-item.h-on {
  background: $bg-color-contents-swiper-item-border 0% 0% no-repeat padding-box;
  opacity: 1;
}

.swiper_root_margin {
  margin: 15px 18px;
}

/* popup */
.small_or_big_btn {
  position: absolute;
  margin: 35px 17px;
}

/* mobile */
.sm {
  .swiper_root_margin {
    margin: 0px 2vw;
  }

  .icon {
    width: 35vw;
    height: 35vw !important;
    // border-radius: 13vw;
    border-radius: 8px;
    margin: 2vw 0px;
  }

  .icon_skin {
    width: 35vw;
    height: 35vw !important;
    // border-radius: 13vw;
    border-radius: 8px;
    margin: 2vw 0px;
  }

  .icon_text {
    width: 23vw;
    height: 5vw;
    font: normal normal bold 3.4vw/4vw Noto;
  }

  .common_btn {
    height: 50px;
  }

  .swiper-item {
    width: 15px;
    height: 15px;
  }
}
</style>
