<template>
  <!-- PC 웹 & 모바일 -->

  <v-main class="fullwidth fullheight bg_card" :class="$mq">
    <v-card
      flat
      tile
      class="mx-auto"
      min-width="320"
      :max-width="$store.state.web_max_width"
      color="transparent"
    >
      <v-row justify="center" class="title_root">
        <v-col cols="12" class="title_root_text">
          {{ $ml.get("main_button_06") }}
        </v-col>
        <div class="title_root_text_line"></div>
        <v-btn
          elevation="0"
          class="title_root_back"
          icon
          v-on:click="$router.go(-1)"
        >
        </v-btn>
      </v-row>

      <v-row justify="center" class="main_root">
        <v-card
          width="100%"
          class="mainCard"
          :class="$mq == 'sm' ? 'rounded-xl' : 'rounded-b-xl'"
        >
          <v-row justify="center" class="main_title_1">
            <v-btn
              elevation="0"
              class="main_root_back"
              icon
              v-on:click="$router.go(-1)"
            >
            </v-btn>
            <v-col
              v-if="$mq != 'sm'"
              cols="12"
              class="main_title_1_text font_black"
            >
              <!-- <img
                class="img_text"
                aspect-ratio="1"
                src="@/assets/ci_x_header.png"
              /> -->
              <span>{{ $ml.get("after_use_list_title") }}</span>
            </v-col>
            <v-col
              v-else
              cols="12"
              class="main_title_1_text main_title_text_font_color"
            >
              {{ $ml.get("after_use_write_m_title2") }}
            </v-col>
          </v-row>
          <v-row class="write_root" :class="$mq" style="">
            <v-form ref="form" class="fullwidth">
              <v-text-field
                class="after_use_write_input"
                :class="$mq == 'sm' ? 'rounded-lg' : 'rounded-lg'"
                :outlined="$mq !== 'sm'"
                solo
                dense
                :height="$mq == 'sm' ? '10vw' : 58"
                :placeholder="$ml.get('after_use_write_name_ph')"
                persistent-hint
                :rules="name_rules"
                :value="input_name"
                @change="v => (input_name = v)"
                required
              ></v-text-field>

              <v-text-field
                class="after_use_write_input"
                :class="$mq == 'sm' ? 'rounded-lg' : 'rounded-lg'"
                :outlined="$mq !== 'sm'"
                :height="$mq == 'sm' ? '10vw' : 58"
                dense
                solo
                :placeholder="$ml.get('after_use_write_title_ph')"
                persistent-hint
                :rules="title_rules"
                :value="input_title"
                @change="v => (input_title = v)"
              ></v-text-field>
            </v-form>

            <div style="width:100%;">
              <v-textarea
                class="rounded-lg after_use_write_textarea"
                dense
                solo
                :outlined="$mq !== 'sm'"
                rows="5"
                :height="$mq != 'sm' ? '370' : '50vw'"
                row-height="30"
                :placeholder="$ml.get('after_use_write_content_ph')"
                no-resize
                hide-details
                :value="input_content"
                @change="v => (input_content = v)"
              >
              </v-textarea>
            </div>

            <v-btn
              class="rounded-0 rounded-b-xl add_img_btn"
              v-on:click="select_file()"
              v-if="files.length <= 0"
            >
              <!-- v-on:click="upload()" -->
              {{ this.$ml.get("after_use_write_img_btn") }}
            </v-btn>

            <div class="rounded-0 rounded-b-xl add_img_btn complete" v-else>
              <div>
                {{ this.$ml.get("after_use_write_img_complete") }}
              </div>
              <img class="ma-2" width="44" src="@/assets/image_icon_x.png" />
            </div>

            <div class="add_img_name" v-show="files.length > 0">
              <span class="complete">Complete - </span>{{ imageName }}

              <v-btn
                elevation="0"
                class="add_img_del_btn"
                icon
                v-on:click="clear_file()"
              >
              </v-btn>
            </div>

            <input
              type="file"
              class="fileUpload"
              style="display: none"
              ref="image"
              accept="image/*"
              @change="onFilePicked"
            />
          </v-row>

          <v-btn
            v-if="$mq === 'sm'"
            class="rounded-0 rounded-b-xl button_1 button_1_effect_m"
            v-on:click="use_write()"
          >
            {{ $ml.get("after_use_write_btn_2") }}
          </v-btn>
        </v-card>

        <v-btn
          v-if="$mq !== 'sm'"
          class="rounded-xl cancel_btn mt-5 mr-10"
          :class="$mq == 'sm' ? 'ma-0' : 'mb-12'"
          content_tap_btn_bg
          v-on:click="$router.go(-1)"
        >
          {{ $ml.get("after_use_list_cancel_btn") }}
        </v-btn>

        <v-btn
          v-if="$mq !== 'sm'"
          class="rounded-xl write_btn mt-5"
          :class="$mq == 'sm' ? 'ma-0' : 'mb-12'"
          content_tap_btn_bg
          v-on:click="use_write()"
        >
          {{ $ml.get("after_use_write_btn_2") }}
        </v-btn>
      </v-row>
    </v-card>
  </v-main>
</template>

<script>
import { Camera } from "@ionic-native/camera";

export default {
  created() {},
  mounted: () => {
    var temp2 = document.getElementsByClassName("animation_top");
    for (var j = 0; j < temp2.length; j++) {
      temp2[j].classList.add("start");
    }
    var temp3 = document.getElementsByClassName("animation_bottom");
    for (var k = 0; k < temp3.length; k++) {
      temp3[k].classList.add("start");
    }
  },
  data: function() {
    return {
      input_name: "",
      input_title: "",
      input_content: "",
      name_rules: [
        value => {
          return (
            value.trim().length > 0 ||
            this.$ml.get("after_use_write_alert_error_3")
          );
        }
      ],
      title_rules: [
        value => {
          return (
            value.trim().length > 0 ||
            this.$ml.get("after_use_write_alert_error_1")
          );
        }
      ],
      files: [],
      imageName: "",
      imageFile: "",
      imageUrl: ""
      // scale: 100,
      // quality: 50,
      // original: {},
      // compressed: {},
      // img: ""
    };
  },
  methods: {
    // upload() {
    //   let compressor = this.$refs.compressor.$el;
    //   compressor.click();
    // },
    // getFiles(obj) {
    //   this.img = obj.compressed.blob;
    //   this.original = obj.original;
    //   this.compressed = obj.compressed;

    //   console.log(this.original);
    //   console.log(this.compressed);
    // },
    checkimgSize() {
      var self = this;
      let fileSize = document.getElementsByClassName("fileUpload")[0].files[0]
        .size;
      var maxSize = 20 * 1024 * 1024; // 20MB
      if (fileSize > maxSize) {
        const title = this.$ml.get("after_use_write_alert_title");
        const info = this.$ml.get("after_use_write_alert_error_4");
        this.$store.commit("alert_show", { title, info });
        self.clear_file();
        return true;
      }
      return false;
    },
    select_file: function() {
      var self = this;
      if (self.$store.state.platform == "web") {
        self.$refs.image.click();
      } else {
        var options = {
          // Some common settings are 20, 50, and 100
          quality: 100,
          destinationType: Camera.DestinationType.FILE_URI,
          // In this app, dynamically set the picture source, Camera or photo gallery
          sourceType: Camera.PictureSourceType.PHOTOLIBRARY,
          //encodingType: Camera.EncodingType.JPEG,
          mediaType: Camera.MediaType.PICTURE
          //allowEdit: true,
          //correctOrientation: true
        };

        Camera.getPicture(options)
          .then(data => {
            try {
              self.files = data;
              var temp = data.split("/");
              var splitname = temp[temp.length - 1];
              temp = splitname.split("?");
              self.imageName = temp[0];

              //TODO: 이미지 저장하기전에 한번 comporessor적용

              self.imageFile = data;
            } catch (e) {
              self.clear_file();
              alert(
                "사진을 가져오는데 실패하였습니다. 새로 고침 후 다시 시도해 주세요."
              );
            }
          })
          .catch(e => {
            //error
            alert(
              "사진을 가져오는데 실패하였습니다. 새로 고침 후 다시 시도해 주세요."
            );
            console.log(e);
          });
      }
    },
    clear_file: function() {
      this.files = [];
      this.imageName = "";
      this.imageFile = "";
      this.imageUrl = "";
      this.$refs.image.value = "";
    },
    onFilePicked(e) {
      this.checkimgSize();
      this.files = e.target.files;
      if (this.files[0] !== undefined) {
        this.imageName = this.files[0].name;
        if (this.imageName.lastIndexOf(".") <= 0) {
          return;
        }
        const fr = new FileReader();
        // fr.readAsDataURL(this.files[0])
        // fr.addEventListener('load', () => {
        //   this.imageUrl = fr.result
        //   this.imageFile = this.files[0] // this is an image file that can be sent to server...
        // })
        fr.readAsDataURL(this.files[0]);

        fr.onload = () => {
          // note using fat arrow function here if we intend to point at current Class context.

          this.imageUrl = fr.result;
          this.imageFile = this.files[0];
          //alert(this.yourImageDataURL);
        };
      } else {
        this.imageName = "";
        this.imageFile = "";
        this.imageUrl = "";
      }
    },
    use_write: function() {
      if (!this.$refs.form.validate()) {
        return;
      } else if (this.input_content.trim().length < 1) {
        const title = this.$ml.get("after_use_write_alert_title");
        const info = this.$ml.get("after_use_write_alert_error_2");
        this.$store.commit("alert_show", { title, info });

        return;
      }

      var self = this;

      //loading
      self.$ionic.loadingController
        .create({
          message: self.$ml.get("loading_text")
        })
        .then(l => {
          l.present();
          self.$store.commit("loading_update", l);
        });

      //api_review_insert
      var api = "api_review_insert.php";
      var param = {
        name: encodeURI(this.input_name),
        content: encodeURI(
          this.input_content.replace(/(?:\r\n|\r|\n)/g, "<br/>")
        ),
        title: encodeURI(this.input_title)
      };
      var file = self.imageFile;

      self.$store.dispatch("apiCallFile", { api, param, file }).then(() => {
        //loading hide
        setTimeout(function() {
          try {
            self.$store.state.loading.dismiss();
          } catch (e) {
            //empty
          }
        }, 100);

        var data = self.$store.state.api_result;
        if (data == false || data == "err") {
          //error
          alert(
            "정보를 가져오는데 실패하였습니다. 새로 고침 후 다시 시도해 주세요."
          );
        } else {
          if (data.ret_code == "S") {
            const title = self.$ml.get("after_use_write_alert_success_title");
            const persistent = true;
            const info = self.$ml.get("after_use_write_alert_success_info");
            const fn = function() {
              self.$router.go(-2);
            };
            self.$store.commit("alert_show", { title, persistent, info, fn });
          } else {
            alert(
              "정보를 가져오는데 실패하였습니다. 새로 고침 후 다시 시도해 주세요."
            );
          }
        }
      });
    }
  },

  watch: {}
};
</script>

<style lang="scss" scoped>
@import "src/assets/css/common";

.bg_card {
  background-attachment: fixed;
  background-image: url("../assets/bg_review.png");
  background-size: cover;
  background-position: bottom;
  // padding-top: 100px !important;
}

.img_text {
  width: 80px;
  margin: 0px 0px -8px 2px;
}

.after_use_write_input {
  font: normal normal normal 18px/24px Noto !important;
  // background-color: $color-white;
}

.after_use_write_textarea {
  // padding-top: 10px;
  // margin-top: 10px;
  font: normal normal normal 18px/24px Noto !important;
  // background-color: $color-white;
}

.add_img_btn {
  height: 60px !important;
  width: 100%;
  background-color: $bf-color-after_use_write-add-img-btn !important;
  color: $ft-color-after_use_write-add-img-btn !important;
  font: normal normal bold 20px/24px Noto !important;
}

.add_img_btn.complete {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  font: normal normal bold 20px/24px Noto !important;
  color: $ft-color-after_use_write-add-img-btn-on !important;
}

.add_img_name {
  width: 100%;
  height: 66px;
  border: 1px solid $bg-color-after_use_write-add-img-name-border;
  border-radius: 20px;
  margin-top: 20px;
  text-align: left;
  padding-left: 20px;
  padding-right: 20px;
  font: normal normal normal 17px/62px Noto;
  color: $ft-color-after_use_write-add-img-name-text;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  background-color: $ft-color-after_use_write-add-img-btn;
}

.add_img_name .complete {
  color: $ft-color-after_use_write-add-img-name-text-complete;
}

.write_root {
  margin: 40px 22px 40px 22px;
}

.write_text_area {
  height: 370px;
}

.add_img_del_btn {
  width: 40px;
  height: 40px;
  float: right;
  margin-top: 10px;
  right: 0px;
  background-image: url("../assets/image_cancle_button_x.png");
  background-size: contain;
}

// .compressor {
//   display: none;
// }

.bg_card {
  background-image: url("../assets/bg_review_m.png");

  .mainCard {
    background: rgba(255, 255, 255, 0.8);
  }

  .after_use_write_input {
    font: normal normal normal 18px/24px Noto !important;
    letter-spacing: 0px;
  }

  .cancel_btn {
    width: 47%;
    height: 60px !important;
    background-color: $write_cancel_button_text !important;
    font: normal normal normal 20px Maple !important;
    color: $m_button_text_white;
    border: 2px solid white;
  }

  .write_btn {
    width: 47%;
    height: 60px !important;
    background-color: $write_button_text !important;
    font: normal normal normal 20px Maple !important;
    color: $m_button_text_white;
    border: 2px solid white;
  }
}

.sm.bg_card {
  /* mobile */
  .write_root {
    margin: 3vw;
  }

  .write_text_area {
    height: 60vw;
  }

  .add_img_btn {
    height: 14vw;
    font: normal normal bold 4.4vw/14vw Noto;
  }

  .add_img_btn_text {
    width: 34vw;
    margin-left: calc(50% - 21vw);
  }

  .add_img_btn_img {
    width: 8vw;
    margin-top: 2vw;
  }

  .add_img_name {
    height: 14vw;
    margin-top: 3vw;
    font: normal normal normal 4.4vw/13vw Noto;
    padding-right: 14vw;
    background-color: $ft-color-after_use_write-add-img-btn;
  }

  .add_img_del_btn {
    position: absolute;
    width: 8vw;
    height: 8vw;
    float: right;
    margin-top: 2.6vw;
    right: 8vw;
  }

  .button_1_effect_m {
    // margin-top: 80px;
    background-color: $m_button_write !important;
    color: $m_button_text_white !important;
    // font: normal normal bold 30px/36px Noto !important;
  }

  .after_use_write_input {
    font: normal normal normal 3.2vw/5vw Noto !important;
  }

  .after_use_write_textarea {
    font: normal normal normal 4.5vw Noto !important;
  }
}
</style>

<style lang="scss">
.after_use_write_textarea .v-text-field__slot {
  padding-top: 10px;
}
</style>
