import { render, staticRenderFns } from "./CounselStart.vue?vue&type=template&id=5cf52206&scoped=true"
import script from "./CounselStart.vue?vue&type=script&lang=js"
export * from "./CounselStart.vue?vue&type=script&lang=js"
import style0 from "./CounselStart.vue?vue&type=style&index=0&id=5cf52206&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5cf52206",
  null
  
)

export default component.exports