<template>
  <v-main class="fullwidth bg_card" :class="$mq">
    <!-- PC 웹 & 모바일 -->

    <InitDialog />

    <div class="pa-0 ma-0 fullwidth fullheight">
      <span v-if="$mq === 'sm'" class="main_bg_logo" />
      <v-card
        flat
        tile
        class="mx-auto d-flex align-end"
        height="calc(100vh - 100px)"
        :min-height="$mq == 'sm' ? 568 : 700"
        :width="1200"
        color="transparent"
      >
        <v-row justify="center" class="main_button_root">
          <!-- <div no-gutters justify="center" style="display:flex;"> -->
          <v-row
            no-gutters
            justify="center"
            :class="$mq !== 'sm' ? 'pt-5' : ''"
          >
            <v-col :cols="$mq == 'sm' ? 6 : 0" v-on:click="goto('info')">
              <v-hover v-slot="{ hover }" class="main_button">
                <v-card
                  elevation="0"
                  class="button_bg bg_1"
                  :class="{ 'on-hover_1': hover }"
                >
                  <div class="hover_effect"></div>
                </v-card>
              </v-hover>
            </v-col>

            <v-col :cols="$mq == 'sm' ? 6 : 0" v-on:click="goto('d_start')">
              <v-hover v-slot="{ hover }" class="main_button">
                <v-card
                  elevation="0"
                  class="button_bg bg_2"
                  :class="{ 'on-hover_2': hover }"
                >
                  <div class="hover_effect"></div>
                </v-card>
              </v-hover>
            </v-col>

            <v-col
              :cols="$mq == 'sm' ? 6 : 0"
              v-on:click="
                $mq === 'sm' ? goto('contents') : goto('counsel_intro')
              "
            >
              <v-hover v-slot="{ hover }" class="main_button">
                <v-card
                  elevation="0"
                  class="button_bg bg_3"
                  :class="{ 'on-hover_3': hover }"
                >
                  <div class="hover_effect"></div>
                  <v-chip
                    class="main_btn_count rounded-xl justify-center"
                    v-if="$store.state.counseling_read == 'Y'"
                  >
                    1
                  </v-chip>
                </v-card>
              </v-hover>
            </v-col>

            <v-col
              :cols="$mq == 'sm' ? 6 : 0"
              v-on:click="
                $mq == 'sm' ? goto('counsel_intro') : goto('contents')
              "
            >
              <v-hover v-slot="{ hover }" class="main_button">
                <v-card
                  elevation="0"
                  class="button_bg bg_4"
                  :class="{ 'on-hover_4': hover }"
                >
                  <div class="hover_effect"></div>
                </v-card>
              </v-hover>
            </v-col>

            <v-col v-if="$mq != 'sm'" v-on:click="goto('after_use_list')">
              <v-hover v-slot="{ hover }" class="main_button">
                <v-card
                  elevation="0"
                  class="button_bg bg_5"
                  :class="{ 'on-hover_5': hover }"
                >
                  <div class="hover_effect"></div>
                </v-card>
              </v-hover>
            </v-col>

            <v-col v-if="$mq != 'sm'" v-on:click="go_home_page()">
              <v-hover v-slot="{ hover }" class="main_button">
                <v-card
                  elevation="0"
                  class="button_bg bg_6"
                  :class="{ 'on-hover_6': hover }"
                >
                  <div class="hover_effect"></div>
                </v-card>
              </v-hover>
            </v-col>
          </v-row>
          <!-- </div> -->
        </v-row>
      </v-card>
      <!-- footer -->
      <div class="bottom_background d-flex align-center justify-center"></div>

      <div class="bottom_line"></div>
      <div
        class="d-flex align-center justify-center footer_root"
        v-html="footer"
      ></div>
    </div>
  </v-main>
</template>

<script>
// import HomeResult from "../components/HomeResult.vue";
import InitDialog from "../components/InitDialog.vue";

export default {
  components: {
    InitDialog
  },
  created() {
    //최상단으로 이동
    if (document.getElementById("inspire") != null) {
      document.getElementById("inspire").scrollTop = 0;
    }
  },
  name: "Home",
  methods: {
    goto: function(type) {
      this.$router.push(type);
    },
    go_home_page: function() {
      window.open("http://www.sangjumind.org/", "_blank");
    },
    yourCallBackFunction() {
      var self = this;

      const timePeriodToExit = 2000;

      if (new Date().getTime() - this.lastTimeBackPress < timePeriodToExit) {
        navigator.app.exitApp();
      } else {
        self.$store.state.open_toast = true;
        setTimeout(function() {
          self.$store.state.open_toast = false;
        }, 2000);
        this.lastTimeBackPress = new Date().getTime();
      }
    }
  },
  mounted: function() {
    // 한번 동작한 뒤에는 animationend를 타지 않아서 setTimeout으로 변경
    // document.querySelector(".bg_card").addEventListener("animationend", function(){
    setTimeout(function() {
      setTimeout(function() {
        document.querySelector(".bg_card").classList.add("step2");

        var temp = document.getElementsByClassName("animation_big");
        for (var i = 0; i < temp.length; i++) {
          temp[i].classList.add("start");
        }
        var temp2 = document.getElementsByClassName("animation_top");
        for (var j = 0; j < temp2.length; j++) {
          temp2[j].classList.add("start");
        }
        var temp3 = document.getElementsByClassName("animation_bottom");
        for (var k = 0; k < temp3.length; k++) {
          temp3[k].classList.add("start");
        }
        var temp4 = document.getElementsByClassName("animation_spin");
        for (var x = 0; x < temp4.length; x++) {
          temp4[x].classList.add("start");
          temp4[x].style.opacity = 1;
        }
      }, 0);
    }, 0);
    // }, false);

    //android backbutton 종료
    document.addEventListener("backbutton", this.yourCallBackFunction, false);
  },
  data: function() {
    return {
      open_toast: true,
      lastTimeBackPress: 0
    };
  },
  computed: {
    footer() {
      return this.$mq == "sm"
        ? this.$ml.get("footer_m")
        : this.$ml.get("footer");
    }
  },
  initializeApp() {
    //android backbutton 종료
    // History Back 안되게...
    this.$store.actions.disableHistoryBack();
  },
  beforeDestroy() {
    //android backbutton 종료
    document.removeEventListener("backbutton", this.yourCallBackFunction);
  }
};
</script>

<style lang="scss" scoped>
@import "src/assets/css/common";
.bg_card {
  background-image: url("../assets/bg_main.png");
  background-attachment: initial;
  // background-position: top left;
  background-position: left;
  background-size: cover;
  // padding-top: 0px !important;
}

// .main_bg_logo {
//   position: absolute;
//   left: 15%;
//   top: 30%;
//   width: 680px;
//   height: 300px;
//   background-image: url("../assets/pc_SUM.png");
// }

.main_button_root {
  margin-bottom: 20px;
  border-top: 2px solid $bg-color-btn-bar-background;
  z-index: 5;
}

.title_sub {
  font-size: 20px;
  font-family: "Noto";
  color: rgb(255, 255, 255);
  margin-top: 55px;
}

.button_bg {
  background-color: transparent;
  background-size: contain;
  max-width: 185px;
  max-height: 150px;
  width: 13vw;
  height: 10vw;
  margin: auto;
  cursor: pointer;
}

.button_bg.bg_1 {
  background-image: url("../assets/com_button_x_1.png") !important;
}

.button_bg.bg_2 {
  background-image: url("../assets/com_button_x_2.png") !important;
}

.button_bg.bg_3 {
  background-image: url("../assets/com_button_x_3.png") !important;
}

.button_bg.bg_4 {
  background-image: url("../assets/com_button_x_4.png") !important;
}

.button_bg.bg_5 {
  background-image: url("../assets/com_button_x_5.png") !important;
}

.button_bg.bg_6 {
  background-image: url("../assets/com_button_x_6.png") !important;
}

// .button_bg.on-hover_1 {
//   background-image: url("../assets/com_button_x_1_o.png") !important;
// }

// .button_bg.on-hover_2 {
//   background-image: url("../assets/com_button_x_2_o.png") !important;
// }

// .button_bg.on-hover_3 {
//   background-image: url("../assets/com_button_x_3_o.png") !important;
// }

// .button_bg.on-hover_4 {
//   background-image: url("../assets/com_button_x_4_o.png") !important;
// }

// .button_bg.on-hover_5 {
//   background-image: url("../assets/com_button_x_5_o.png") !important;
// }

// .button_bg.on-hover_6 {
//   background-image: url("../assets/com_button_x_6_o.png") !important;
// }

.main_btn_count {
  position: absolute;
  z-index: 100;
  font-family: "Noto";
  font-size: 24px !important;
  color: $bg-color-main-count;
  top: -18px;
  right: 0px;
  width: 46px;
  height: 46px;
}

.bottom_background {
  margin-top: -160px;
  width: 100%;
  height: 171px;
  // background-color: $bg-color-contents-footer !important;
  // opacity: 0.5;
  z-index: 0;
}

/*footer css*/
.footer_root {
  height: 100px;
  font-family: Noto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: $ft-color-footer !important;
  background-color: $bg-color-pc-footer !important;
}

.button_bg.on-hover_1 > .hover_effect,
.button_bg.on-hover_2 > .hover_effect,
.button_bg.on-hover_3 > .hover_effect,
.button_bg.on-hover_4 > .hover_effect,
.button_bg.on-hover_5 > .hover_effect,
.button_bg.on-hover_6 > .hover_effect {
  position: absolute;
  border-top: 5px solid $bg-color-button-hover-line-color;
  width: 70%;
  left: 15%;
  margin-top: -24px;
}

.animation_top {
  margin-bottom: 0px;
  z-index: 1;
}

/* mobile */
.sm.bg_card {
  background-image: url("../assets/bg_main_m.png");
  // background-size: 110% 120%;
  background-position: bottom left;

  .main_button_root {
    padding: 30px 4vw 0px 4vw;
    margin: 0px;
    border: none;
    // background-color: $bg-color-footer !important;
  }

  .main_button_root .col {
    display: flex;
    justify-content: center;
    border-top: 2px solid $bg-color-btn-bar-background-m !important;
  }

  .main_button_root .col:nth-child(-n + 2) {
    // margin-bottom: 6vw;
  }

  .button_bg {
    width: 84% !important;
    height: 30vw !important;
    max-width: 100% !important;
    max-height: 30vw !important;
    background-size: contain;
    background-position: center;
    cursor: pointer;
    box-shadow: none !important;
    display: flex;
    justify-content: center;
  }

  .main_bg_logo {
    background-image: url("../assets/m_SUM.png");
    //   position: absolute;
    //   left: 15%;
    //   top: 30%;
    //   width: 680px;
    //   height: 300px;
    position: absolute;
    left: 5vw;
    top: 10%;
    width: 100%;
    height: 30vw;
    // transform: scale(1);
    background-size: contain;
  }

  .button_bg.bg_1 {
    background-image: url("../assets/com_button_m_1.png") !important;
  }

  .button_bg.bg_2 {
    background-image: url("../assets/com_button_m_2.png") !important;
  }

  .button_bg.bg_3 {
    background-image: url("../assets/com_button_m_4.png") !important;
  }

  .button_bg.bg_4 {
    background-image: url("../assets/com_button_m_3.png") !important;
  }

  // .button_bg.on-hover_1 {
  //   background-image: url("../assets/com_button_m_1_o.png") !important;
  // }

  // .button_bg.on-hover_2 {
  //   background-image: url("../assets/com_button_m_2_o.png") !important;
  // }

  // .button_bg.on-hover_3 {
  //   background-image: url("../assets/com_button_m_4_o.png") !important;
  // }

  // .button_bg.on-hover_4 {
  //   background-image: url("../assets/com_button_m_3_o.png") !important;
  // }

  .footer_root {
    position: absolute;
    width: 100%;
    // height: calc(17vw + 100px);
    // padding-bottom: 100px;
    font-size: 2.4vw;
    text-align: left;

    background-color: $bg-color-footer !important;
  }

  .button_bg.on-hover_1 > .hover_effect,
  .button_bg.on-hover_2 > .hover_effect,
  .button_bg.on-hover_3 > .hover_effect,
  .button_bg.on-hover_4 > .hover_effect,
  .button_bg.on-hover_5 > .hover_effect,
  .button_bg.on-hover_6 > .hover_effect {
    border-top: 4px solid $bg-color-button-hover-line-color;
    width: 26vw;
    margin-top: -3px;
  }

  .bottom_background {
    display: none !important;
  }

  .animation_top {
    border: none;
  }
}
</style>
