<template>
  <v-main class="fullwidth fullheight bg_card" :class="$mq">
    <!-- PC 웹 & 모바일 -->
    <v-card
      flat
      tile
      class="mx-auto"
      min-width="320"
      :max-width="$store.state.web_max_width"
      color="transparent"
    >
      <v-row justify="center" class="title_root">
        <v-col cols="12" class="title_root_text">
          {{ $ml.get("main_button_04") }}
        </v-col>
        <div class="title_root_text_line"></div>
        <v-btn
          elevation="0"
          class="title_root_back"
          icon
          v-on:click="$router.go(-1)"
        >
        </v-btn>
      </v-row>

      <v-row justify="center" class="main_root">
        <!-- <v-card
          :class="$mq == 'sm' ? 'rounded-xl' : 'rounded-b-xl'"
          :color="$mq == 'sm' ? '#FFFFFFBB' : '#FFFFFF'"
          width="100%"
          style="box-shadow: none;"
        > -->
        <v-card
          :class="$mq == 'sm' ? 'rounded-xl' : 'rounded-b-xl'"
          width="100%"
          style="box-shadow: none; background-color: transparent;"
        >
          <v-row
            v-if="$mq == 'sm'"
            justify="center"
            class="main_title_1 contents_main_title_1"
          >
            <v-btn
              elevation="0"
              class="contents_root_back"
              icon
              v-on:click="$router.go(-1)"
            >
            </v-btn>
            <v-col
              cols="12"
              class="main_title_1_text contents_main_title_1_text"
            >
              {{ $ml.get("main_button_04") }}
            </v-col>
          </v-row>
          <v-row
            class="ma-0 content_tap_btn_bg"
            :class="$mq !== 'sm' ? 'pa-3' : ''"
            :style="
              $mq !== 'sm' ? 'width: 800px; align-items:flex-end; margin-left: 70px !important;' : ''
            "
          >
            <!-- <div style="display= flex; justify-content: center;"> -->
            <v-row
              v-for="(el, index) in category_name_List"
              :key="index"
              class="pa-0 d-flex flex-row align-center justify-center content_tap_btn"
              :class="[
                num === index ? 'on_0' : '',
                $mq === 'sm' ? 'flex-column' : ''
              ]"
            >
              <v-img
                v-if="$mq !== 'sm'"
                class="contents_tap_pc_img"
                :src="new_category_icon_list[`${index}`]"
                v-on:click="menu(index)"
                contain
              >
              </v-img>

              <!-- <v-row class="d-flex align-center">
                {{ category_name_List[`${index}`] }}
              </v-row> -->
              <div
                v-if="$mq === 'sm'"
                class="category_name"
                style="margin-right: 20px; cursor: pointer;"
                v-on:click="menu(index)"
              >
                {{ category_name_List[`${index}`] }}
              </div>
              <div v-if="$mq === 'sm'" class="category_on_effect"></div>
              <div
                v-if="$mq !== 'sm' && index !== category_name_List.length - 1"
                class="slash_pc_tap"
                style="color: white; cursor: default;"
              >
                /
              </div>
            </v-row>
            <!-- </div> -->
          </v-row>

          <!-- <v-row class="ma-0" v-if="category_list_type[num] === 'list'">
            <v-img class="" src="@/assets/Artboard_16.jpg" contain> </v-img>
          </v-row> -->
          <div class="v_card_below_part">
            <div v-if="category_list_type[num] === 'thumbnail'">
              <ContentsThumb :healing_list="child_data" />
            </div>
            <div v-else-if="category_list_type[num] === 'list'">
              <ContentsList :data="child_data" />
            </div>
            <div v-else-if="category_list_type[num] === 'thanks'">
              <div v-if="category_name_List[num] === '랜선여행'">
                <lan-travel />
              </div>
              <div v-else-if="category_name_List[num] === '낮잠ASMR'">
                <nap />
              </div>
            </div>
            <!-- <div v-else-if="category_list_type[num] === 'thanks'">
            <lan-travel />
          </div> -->

            <div v-else>
              <v-progress-circular
                :size="100"
                :width="7"
                color="#333333"
                indeterminate
                style="margin-bottom:50px;"
              ></v-progress-circular>
            </div>
          </div>
        </v-card>
      </v-row>
    </v-card>
  </v-main>
</template>

<script>
import ContentsList from "../components/ContentsList.vue";
import ContentsThumb from "../components/ContentsThumb.vue";
// import ThanksDiary from "../components/ThanksDiary.vue";
import Nap from "../components/Nap.vue";
import LanTravel from "../components/LanTravel.vue";
// let vm = null;

export default {
  components: {
    ContentsList,
    ContentsThumb,
    // ThanksDiary,
    Nap,
    LanTravel
  },

  created() {
    // //api_content_list
    // console.log("test");
    // // console.log(this.category_icon_list);
    // console.log(this.category_icon_list.length);
    // for (let i = 0; i < this.category_icon_list.length; i++) {
    //   console.log(this.$route.query.type);
    //   if (this.$route.query.type === i) {
    //     console.log(this.new_category_icon_list);
    //     this.new_category_icon_list.push(this.category_icon_on_list[i]);
    //   } else {
    //     this.new_category_icon_list.push(this.category_icon_off_list[i]);
    //   }
    // }

    var self = this;
    var api = "content.php";
    var param = {};

    self.$store.dispatch("apiCall", { api, param }).then(() => {
      var data = self.$store.state.api_result;
      // console.log(data);
      if (data == false || data == "err") {
        // error
        alert(
          "정보를 가져오는데 실패하였습니다. 새로 고침 후 다시 시도해 주세요."
        );
      } else {
        try {
          if (data.ret_code == "S") {
            for (let i = 0; i < data.category_list.length; i++) {
              if (data.category_list[i].category_title !== "healingtour") {
                this.category_name_List.push(
                  data[`${data.category_list[i].category_title}_category_name`]
                );

                this.category_name_sub_List.push(
                  data[`${data.category_list[i].category_title}_detail`]
                );

                this.category_healing_list.push(
                  data[`${data.category_list[i].category_title}_list`]
                );

                this.category_icon_list.push(
                  data[`${data.category_list[i].category_title}_icon`]
                );

                this.category_list_type.push(
                  data[`${data.category_list[i].category_title}_list_type`]
                );

                this.category_icon_on_list.push(
                  data[`${data.category_list[i].category_title}_icon_img_on`]
                );

                this.category_icon_off_list.push(
                  data[`${data.category_list[i].category_title}_icon_img_off`]
                );
              }
            }

            var type = this.$route.query.type;

            //! 변경
            self.menu(type);
            // self.menu(1);
          } else {
            // alert(
            //   "정보를 가져오는데 실패하였습니다. 새로 고침 후 다시 시도해 주세요."
            // );
          }
        } catch (e) {
          // alert("정보를 가져오는데 실패하였습니다. 새로 고침 후 다시 시도해 주세요.");
        }
      }
    });
  },
  data: function() {
    return {
      num: 0,
      title: "",
      title_sub: "",
      category_healing_list: [],
      category_name_List: [],
      category_icon_list: [],
      category_name_sub_List: [],
      category_list_type: [],
      category_icon_on_list: [],
      category_icon_off_list: [],
      child_data: [],
      clickCheck: false,
      new_category_icon_list: []
    };
  },

  computed: {},
  methods: {
    clickValidation() {
      if (!this.clickCheck) {
        this.clickCheck = !this.clickCheck;
      } else {
        this.clickCheck = !this.clickCheck;
      }
    },

    menu: function(val) {
      this.num = parseInt(val, 10);
      if (!Number.isInteger(this.num)) {
        this.num = 0;
      }

      this.title_sub = this.category_name_sub_List[this.num];
      this.child_data = this.category_healing_list[this.num];

      this.new_category_icon_list = [];
      for (let i = 0; i < this.category_icon_on_list.length; i++) {
        if (this.num === i) {
          this.new_category_icon_list.push(this.category_icon_on_list[i]);
        } else {
          this.new_category_icon_list.push(this.category_icon_off_list[i]);
        }
      }

      this.$router.replace({ query: { type: this.num } }).catch(() => {}); //router
    }
  },
  watch: {}
};
</script>

<style lang="scss" scoped>
@import "src/assets/css/common";
.bg_card {
  background-image: url("../assets/bg_healing.png");
}

.content_tap_btn_bg {
  // background-color: $bg-color-main-title;
  background-color: transparent;
}

.content_tap_btn {
  // display: none !important;
  height: 80px;
  text-align: center;
  font: normal normal bold 20px/30px Noto;
  letter-spacing: 0px;
  color: $ft-color-contents-tap;
  // cursor: pointer;
}

.content_tap_btn.on_0 {
  color: $ft-color-contents-tap-on;
}

.thanks_healing_color {
  background-color: $bg-color-contents-tanksdiary;
}

.thanks_contents_info {
  background-color: $bg-color-contents-tanksdiary;
  color: $ft-color-contents-tanksdiary;
  font-weight: bold;
}

.v_card_below_part {
  background-color: rgb(232, 236, 236);
}

.contents_main_title_1 {
  background-color: $bg-color-btn-2;
}

.contents_main_title_1_text {
  color: $ft-color-contents-title-text;
}

.content_tap_btn.on_0 > .category_on_effect {
  position: absolute;
  opacity: 0.5;
  border-bottom: 8px solid $ft-color-contents-tap-pc-on;
  padding-top: 15px;
  margin-left: -28px;
  // left: 55%;
  width: 77px;
  // border-bottom: 1px solid $ft-color-contents-tap-m-on;
}

.contents_tap_pc_img {
  cursor: pointer;
  width: 90px;
  height: 31px;
  // background-size: contain;
  margin-right: 18px;
}

.slash_pc_tap {
  margin-right: 38px;
  // margin-left: 30px;
}

.contents_root_back {
  display: block !important;
  position: absolute !important;
  width: 6vw !important;
  height: 6vw !important;
  margin: 4vw;
  left: 0px;
  top: 0vw;
  background-size: contain;
  background-image: url("../assets/ios-arrow-dropleft.png") !important;
}

/* mobile */
.sm.bg_card {
  background-image: url("../assets/bg_healing_m.png");

  .content_tap_btn_bg {
    background-color: $bg-color-contents-tap-m;
    flex-flow: nowrap;
  }

  .content_tap_btn {
    // height: 6vw;
    height: 10vw;
    // padding-left: 10px !important;
    font: normal normal bold 2.8vw/5vw Noto;
    white-space: nowrap;
    // font: normal normal bold 10px/10px Noto;
    color: $ft-color-contents-tap-m;
    // margin-right: 1vw;
    // margin-left: 2vw;
    z-index: 2;
  }

  .content_tap_btn.on_0 {
    color: $ft-color-contents-tap-m-on;
  }

  .content_tap_btn.on_0 > .category_name {
    border-bottom: 1px solid $ft-color-contents-tap-m-on;
  }

  .category_name {
    // width: 12vw;
    margin-right: 1vw !important;
    // margin-left: 1vw;
  }

  .content_tap_btn.on_0 > .category_on_effect {
    display: none;
  }
}
</style>
