<template>
  <v-main class="fullwidth fullheight bg_card" :class="$mq">
    <!-- PC 웹 & 모바일 -->
    <v-card
      flat
      tile
      class="mx-auto"
      min-width="320"
      :max-width="$store.state.web_max_width"
      color="transparent"
    >
      <v-row justify="center" class="title_root">
        <v-col cols="12" class="title_root_text">
          {{ $ml.get("main_button_02") }}
        </v-col>
        <div class="title_root_text_line"></div>
        <v-btn
          elevation="0"
          class="title_root_back"
          icon
          v-on:click="$router.go(-1)"
        >
        </v-btn>
      </v-row>

      <v-row justify="center" class="main_root">
        <v-card
          :class="$mq == 'sm' ? 'rounded-xl' : 'rounded-b-xl'"
          color="transparent"
          width="100%"
        >
          <v-row justify="center" class="main_title_1">
            <v-btn
              elevation="0"
              class="main_root_back"
              icon
              v-on:click="$router.go(-1)"
            >
            </v-btn>
            <v-col
              cols="12"
              class="main_title_1_text main_title_text_font_color"
            >
              {{ title }}
            </v-col>
          </v-row>

          <v-card
            v-if="$store.state.diagnosis_data.survey_type != 'img'"
            elevation="0"
            min-height="160"
            class="d-flex align-center justify-center rounded-0 ma-auto info_text_0 big"
            v-html="list[step].question_title"
          >
          </v-card>
          <v-card
            v-else
            elevation="0"
            class="d-flex align-center justify-center rounded-0 ma-auto info_text_0 big"
          >
            <v-img
              :src="list[step].questiont_img"
              :max-height="$mq == 'sm' ? '40vw' : 300"
              contain
            >
            </v-img>
          </v-card>
          <v-list flat class="pa-0">
            <v-list-item-group>
              <v-list-item
                v-for="(item, index) in list[step].exemple_list"
                :key="index"
                class="list dark"
                @click="next(item.exemple_text, item.exemple_point)"
              >
                <v-list-item-content>
                  <v-list-item-title
                    class="d-flex align-center justify-center select_text"
                    v-html="item.exemple_text"
                  ></v-list-item-title>
                </v-list-item-content>
                <div class="border_bottom"></div>
              </v-list-item>
            </v-list-item-group>
          </v-list>

          <v-btn class="rounded-0 rounded-b-xl button_2" v-on:click="back()">
            {{ $ml.get("d_s_text_btn") }}
          </v-btn>
        </v-card>
      </v-row>
    </v-card>
  </v-main>
</template>

<script>
export default {
  data: function() {
    return {
      title: "",
      list: [
        {
          exemple_list: "",
          idx: "",
          question_title: "",
          questiont_order: ""
        }
      ],
      step: 0,
      result: []
    };
  },
  computed: {},
  methods: {
    next: function(text, point) {
      this.step++;
      this.result.push({ text: text, point: point });
      if (this.step == this.list.length) {
        //param 만들기
        let param = {};
        let num = 1;
        param.idx = this.$store.state.diagnosis_data.idx;
        this.result.forEach(item => {
          if (item.point == "성별") {
            param.sex = encodeURI(item.text);
          } else if (item.point == "연령") {
            param.age = encodeURI(item.text);
          } else if (item.point == "독거여부") {
            param.solitude = encodeURI(item.text);
          } else if (item.point == "거주지") {
            param.residence = encodeURI(item.text);
          } else {
            param["q" + num] = encodeURI(item.text);
            param["q" + num + "_point"] = item.point;
            num++;
          }
        });

        //api_counseling_insert
        var self = this;
        var api = "api_survey_question_answer_insert.php";

        self.$store.dispatch("apiCall", { api, param }).then(() => {
          var data = self.$store.state.api_result;

          if (data == false || data == "err") {
            // error
            alert(
              "정보를 가져오는데 실패하였습니다. 새로 고침 후 다시 시도해 주세요."
            );
          } else {
            if (data.ret_code == "S") {
              self.$store.state.diagnosis_data_result = data;
              self.$router.push("/d_result");
            } else {
              alert(data.ret_msg);
            }
          }
        });
      }
    },
    back: function() {
      if (this.step <= 0) {
        this.$router.go(-1);
      } else {
        this.step--;
        this.result.pop();
      }
    }
  },
  created() {
    this.title = this.$store.state.diagnosis_data.title;
    //api_content_list
    var self = this;
    var api = "api_survey_question_list.php";
    var param = {
      idx: this.$store.state.diagnosis_data.idx
    };

    self.$store.dispatch("apiCall", { api, param }).then(() => {
      var data = self.$store.state.api_result;

      if (data == false || data == "err") {
        // error
        alert(
          "정보를 가져오는데 실패하였습니다. 새로 고침 후 다시 시도해 주세요."
        );
      } else {
        if (data.ret_code == "S") {
          if (self.$store.state.diagnosis_data.survey_type == "img") {
            self.list = data.list;
          } else {
            self.list = [];
            let temp = data.user_info;
            temp.forEach(item => {
              let rename_list = [];
              item.exemple.forEach(text =>
                rename_list.push({
                  exemple_text: text.text,
                  exemple_point: item.title
                })
              );
              self.list.push({
                exemple_list: rename_list,
                idx: "0",
                question_title: item.title,
                questiont_order: "0"
              });
            });
            data.list.forEach(item => {
              self.list.push(item);
            });
          }
        } else {
          alert(
            "정보를 가져오는데 실패하였습니다. 새로 고침 후 다시 시도해 주세요."
          );
        }
      }
    });
  },
  watch: {}
};
</script>

<style lang="scss" scoped>
@import "src/assets/css/common";

.bg_card {
  background-image: url("../assets/bg_self.png");
}

.select_text {
  height: 50px;
  align-items: center;
  font: normal normal bold 28px/32px Noto !important;
  letter-spacing: 0px;
  color: $ft-color-title-white;
}

.info_text_0.big {
  font: normal normal bold 28px/34px Noto;
}

.sm.bg_card {
  background-image: url("../assets/bg_self_m.png");

  .main_title_1_text.big {
    font: normal normal normal 5vw/6vw Noto;
  }

  .select_text {
    height: calc(16vw - 24px);
    font: normal normal normal 4vw/5vw Noto !important;
    text-align: left;
  }

  .info_text_0.big {
    background-color: $bg-color-btn-2-m;
  }
}
</style>
