<template>
  <v-main class="fullwidth fullheight bg_card" :class="$mq">
    <!-- PC 웹 & 모바일 -->
    <v-card
      flat
      tile
      class="mx-auto"
      min-width="320"
      :max-width="$store.state.web_max_width"
      color="transparent"
    >
      <v-row justify="center" class="title_root">
        <v-col cols="12" class="title_root_text">
          {{ $ml.get("main_button_02") }}
        </v-col>
        <div class="title_root_text_line"></div>
        <v-btn
          elevation="0"
          class="title_root_back"
          icon
          v-on:click="$router.go(-1)"
        >
        </v-btn>
      </v-row>

      <v-row justify="center" class="main_root">
        <v-card
          :class="$mq == 'sm' ? 'rounded-xl' : ''"
          color="transparent"
          width="100%"
        >
          <v-row justify="center" class="main_title_1 d_start_bg">
            <v-btn
              elevation="0"
              class="main_root_back"
              icon
              v-on:click="$router.go(-1)"
            >
            </v-btn>
            <v-col
              cols="12"
              class="main_title_1_text"
              :class="$mq == 'sm' ? '' : 'font_black'"
            >
              {{ title }}
            </v-col>
          </v-row>
          <v-row
            v-if="$mq == 'sm'"
            justify="center"
            class="main_title_1 sub d_start_bg_m"
          >
            <v-col cols="12" class="main_title_1_text d_start_title">
              {{ $ml.get("d_s_title_m") }}
            </v-col>
          </v-row>

          <DiagnosisStartList :list="api_result.list" :depth="0" />

          <v-row v-if="$mq == 'sm'" justify="center" class="main_title_1 sub">
            <v-col cols="12" class="main_title_1_text"> </v-col>
          </v-row>
        </v-card>
      </v-row>
    </v-card>
  </v-main>
</template>

<script>
import DiagnosisStartList from "../components/DiagnosisStartList.vue";

export default {
  components: {
    DiagnosisStartList
  },
  data: function() {
    return {
      api_result: {}
    };
  },
  computed: {
    title() {
      return this.$mq == "sm"
        ? this.$ml.get("main_button_02")
        : this.$ml.get("d_s_title");
    }
  },
  methods: {},
  created() {
    var self = this;
    self.api_result = self.$store.state.diagnosis_data_text;
  },
  mounted: function() {},
  watch: {}
};
</script>

<style lang="scss" scoped>
@import "src/assets/css/common";

.bg_card {
  background-image: url("../assets/bg_self.png");
}

.d_start_bg {
  background-image: url("../assets/d_sub_bg.png");
  background-position: center;
  background-size: cover;
  height: 180px;
}

.sm.bg_card {
  background-attachment: fixed;
  background-image: url("../assets/bg_self_m.png");

  .d_start_bg {
    background-image: none;
  }

  .d_start_bg_m {
    background-image: url("../assets/d_sub_bg_m.png");
    background-position: center;
    background-size: cover;
    height: 21vw !important;
  }

  .d_start_title {
    font-weight: normal !important;
    color: $ft-diagnosisStart-sub-title !important;
  }
}

/* mobile */
</style>
